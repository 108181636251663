import React from "react"
import { Helmet } from "react-helmet"
import { Box, Flex } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import { Container } from "../components/Container"
import { Heading } from "../components/Heading"
import { Layout } from "../layouts/default"

// Sanity helpers
import { FormulationChallengeBlock } from "../components/Blocks/FormulationChallengeBlock"
import { DownloadListing } from "../components/DownloadListing"

const DownloadPage = ({ pageContext }) => {
  const { locale } = pageContext

  return (
    <Layout>
      <Helmet>
        <meta
          name="title"
          content="Micropore Technologies Literature Download Page"
        />
        <meta
          name="description"
          content="Downloadable documents from Micropore Technologies"
        />
      </Helmet>

      <Box height="500px" position="relative" mb={-24} bg="blue.100">
        <StaticImage
          src="../images/downloads.jpg"
          alt="Latest News Banner"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
      </Box>
      <Container px={0}>
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          justifyContent={{ lg: "space-between" }}
        >
          <Box
            bg="white"
            width={{ lg: "50%" }}
            p={{ base: 4, md: 12 }}
            pb={0}
            pos="relative"
            zIndex={2}
          >
            <Heading fontSize="40px" mb={2}>
              Downloads
            </Heading>
          </Box>
        </Flex>

        <DownloadListing locale={locale} />

        <Container
          bg="white"
          pt={4}
          borderTopWidth={2}
          borderTopColor="gray.300"
        >
          <FormulationChallengeBlock locale={locale} />
        </Container>
      </Container>
    </Layout>
  )
}

export default DownloadPage
