import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button, SimpleGrid, Box, Link as ChakraLink } from "@chakra-ui/react"
import { Heading } from "../Heading"
import { Container } from "../Container"
import { getFluidProps } from "../../lib/sanity"

export const DownloadListing = ({ locale = "en" }) => {
  const data = useStaticQuery(graphql`
    {
      sanityDownloads {
        downloadList {
          description {
            en
            fr
          }
          title {
            en
            fr
          }
          thumbnail {
            asset {
              id
            }
          }
          downloadUpload {
            asset {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <Container bg="white" mb={20}>
      <SimpleGrid columns={{ base: 1, lg: 3 }} gap={16}>
        {data.sanityDownloads &&
          data.sanityDownloads.downloadList.length > 0 &&
          data.sanityDownloads.downloadList.map(paper => {
            return (
              <Box
                pb={2}
                shadow="lg"
                width="100%"
                borderWidth="1px"
                overflow="hidden"
                display="flex"
                flexDir="column"
              >
                <Box>
                  {paper.thumbnail?.asset ? (
                    <GatsbyImage
                      image={getFluidProps({
                        id: paper.thumbnail.asset.id,
                      })}
                      style={{ height: 200 }}
                      alt={paper.description[locale] || paper.description.en}
                    />
                  ) : (
                    <Box backgroundColor="gray.100" height="200px" />
                  )}

                  <Box p="4">
                    <Heading
                      as="h3"
                      fontSize={20}
                      bg="white"
                      padding="8px 16px 10px"
                      mb={4}
                    >
                      {paper.title[locale] || paper.title.en}
                    </Heading>

                    {paper.downloadUpload && (
                      <Box px={4}>
                        <Button
                          bg="blue.100"
                          _hover={{ bg: "brand.100", textDecor: "none" }}
                          as={ChakraLink}
                          href={paper.downloadUpload.asset.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Download
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            )
          })}
      </SimpleGrid>
    </Container>
  )
}
